export const exData = [
  {
    id: 0,
    name: "Mr. Cuthbert Gumbochuma",
    image: require("../images/team/gumbochuma.jpeg"),
    position: "Board Chair",
    description:
      "A seasoned Banker with over 20 years of experience in Central and Commercial Banking. He worked for 14 years at the Reserve Bank of Zimbabwe, which he later on left and joined Trust Bank (ABC Bank). Currently, he is focusing on individual entrepreneurship with farming as the mainline. He is a holder of a BSc in Economics Honors which he attained at the University of Zimbabwe.",
  },
  {
    id: 1,
    name: "Ms. Nobuhle Ndlovu",
    image: require("../images/team/nn.jpg"),
    position: "Director & Managing Executive ",
    description:
      "Nobuhle Ndlovu has been with Webs since its inception. Prior to her being appointed Executive Director, she was the Sales and Marketing manager where she consistently exceeded sales targets as the business grew by over 30% annually. She brings with her over 20 years experience working in various capacities within the private sector. She holds a BCom from UNISA.",
  },
  {
    id: 2,
    name: "Mr. Lloyd Dube ",
    image: require("../images/team/dube.jpg"),
    position: "Board Director",
    description:
      "A Senior Project Manager with 10+ years’ experience in delivering strategic projects in the financial services sector."
},

{
  id: 3,
  name: "Mrs. Hope Marere ",
  image: require("../images/team/marere.jpg"),
  position: "Board Member",
  description:
    "A Retail Banking professional with experience that spans over 20 years. She holds an MBA from the Management College of Southern Africa and BCom Finance from the National University of Science and Technology (NUST)."
},

{
  id: 4,
  name: "Ms. Edith Utete",
  image: require("../images/team/edith.jpg"),
  position: "Board Member",
  description:
    " A digital media use expert, online safety and digital wellness advocate, an attorney and author with a wealth of over twenty years’ experience as a legal practitioner. She is also a Cyber-Smart Parenting and Business Consultant assisting children, families, schools and businesses to use the internet safely."
},
 
];

export const teamData = [
  {
    id: 5,
    name: "Mr. James Chetsanga",
    image: require("../images/team/JC.jpg"),
    position: "Business Development Executive",
    description:
      "James Chestanga has over 27 years of rounded IT experience from junior to executive levels working in various African countries in business development, customer and vendor relationship management, software development, system integration, business intelligence and project management. He has worked in different sectors including but not limited to banking and finance, manufacturing, retail, healthcare, ICT solution providers, Public Sector – Government, Local authorities and utilities, FMCG, Mining and Fintech. He Holds a BSc in Information systems and Psychology from UNISA.",
  },
  {
    id: 6,
    name: "Mrs. Tariro Mwashaireni",
    image: require("../images/team/tarie.jpg"),
    position: "Executive Assistant",
    description:
      "Goal-oriented and self-driven, Tariro is an accomplished Executive Assistant with seven years of experience in the banking and micro-insurance sectors. Her expertise spans risk management, compliance, and legal advisory. She has a proven track record in contracts and policy drafting, meticulous reviewing, and successful implementation. She holds a Bachelor of Business Studies Degree with Honours in Banking and Finance from the University of Zimbabwe (UZ) and a Bachelor of Laws Degree from the University of South Africa (UNISA).",
  },
  {
    id: 7,
    name: "Mrs. Unique Zimuto",
    image: require("../images/team/unique.jpg"),
    position: "Marketing Consultant",
    description:
      "Unique Chiza Zimuto is a Development practitioner, Media practitioner, Digital Content Producer, Documentary Producer and Director, US Embassy Grantee, A Nominee of the American Election Reporting Tour, Data Analyst, Programs Manager of Heart and Soul Digital Radio Station, Peer educator and youth advocate. She was selected to be the first youth representing Zimbabwe at the Global Thinkers Forum and she is actively mentored by FemiOke a journalist and talk show host from Aljazeera (Washington D.C) .Unique Zimuto has alsobeen featured on some ofthe world’s biggest news networks Aljazeera (On the Stream) whilst she interviewed Africa’s Billionaire Mo Ibrahim on good governance in Zimbabwe and Africa. Unique is also working with Global Youth Climate in partnership with Oxford University to train on youth fighting Climate Change.Unique is currently one of the most successful young people in the Zimbabwe media industry who is communicating for change through designing and developing concepts that have been endorsed by International and national organizations that turn research papers into TV and Radio talk shows that help the audience understand what’s happening within our local and global communities on a development landscape. She is passionate about giving young people a voice to speak out and be heard on different media platforms",
  },{
    id: 8,
    name: "Mr. Paul K Gudu",
    image: require("../images/team/Paul.jpg"),
    position: "Product Development Manager",
    description:
      "Paul brings experience developing consumer-focused web sites using HTML5, React, JQuery, PHP and JavaScript. His work also involves building products for desktop, phone and mobile app users, meeting highest standards for web design, user experience, best practices, usability and speed. He resends to client challenges by designing and developing solutions. Translating solutions into code and working across many different APIs, third-party integrations and databases. He holds a BSc in software engineering from the University of Wuhan.",
  },
  {
    id: 9,
    name: "Mr. Kudakwashe A Mugaragumbo",
    image: require("../images/team/kuda.jpg"),
    position: "Cards and Payment Solutions Manager",
    description:
      "Dedicated and results-oriented professional with a proven track record in the payments industry, specializing in Postilion. Skilled in all aspects of designing, implementing, and optimizing payment systems to drive operational efficiency and enhance customer satisfaction. Proficient in working with ACI Postilion products and experienced in integrating APIs, ISO 8583, SQL Databases, Windows Server, eCommerce, POS, Mobile, Cards, Payment Solutions, Integration of POS devices with a payment system and ATMs. Adaptable and resilient, adept at tackling challenges head-on and making critical decisions.",
  },
  
  {
    id: 10,
    name: "Ms. Cecilia Ngwaru",
    image: require("../images/team/cecilia.jpg"),
    position: "Administrator",
    description:
      "Organised, passionate people oriented Office Administrator, having a Bachelor's of Business Administration degree. Experience spans brand product Ambassador and Business Management intern. Supports the team and members manage and maximise their time and resources whilst delivering our products and services.",
  },
  {
    id: 11,
    name: "Mr Cliff Kuuzha",
    image: require("../images/team/cliff.jpg"),
    position: "Systems Architect",
    description:
      "Systems Architect with a strong problem-solving ability and expertise in creating robust, scalable solutions, providing technical leadership, and integrating various system components. Holds a BSc in Computer Science and has extensive work experience, including roles as Systems Architect at Webs for Africa Private Limited, IT Support Engineer at Misrah Innovative Solutions, and Technical Support Engineer (Internship) at OK ZIMBABWE Pvt Ltd, where he has demonstrated a dedication to improving business processes and enhancing organizational efficiency through comprehensive system documentation, proactive issue resolution, Microsoft server configuration and domain administration.",
  },
];
