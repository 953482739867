import React, { Component } from 'react'
import NavBanner from '../components/nav_banner'
import TitleComponent from '../components/title_component'
import Footer from '../components/footer'
import TabsComponent from '../components/tabs_component'
import HeadingComponent from '../components/heading_component'
import TeamComponent from '../components/team_component'
import EnviromentalComponent from '../components/enviromental_component'

export default class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
        <NavBanner isAbout={true} />
        <TitleComponent isWelcome={true} />
       {/* <HeadingComponent
          hasSubtitle={false}
          isEnvironmental={true}
        />
        <EnviromentalComponent />*/}

        <HeadingComponent
          hasSubtitle={false}
          isPartnersAndClientsHeader={true}
        />
        <TabsComponent />
        <HeadingComponent
          hasSubtitle={false}
          isPartnersAndClientsHeader={false}
          isTeamHeader={true}
        />
        <TeamComponent />
        <Footer />
      </>
    )
  }
}
